@import "../../_globalColor";

.modal-container {
  padding-top: 20px;

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    input,
    textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      transform: none;
    }
  }

  .contact-success {
    text-align: center;

    .fa-check-square {
      color: #00b3ff;
      font-size: 143px;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 30px;
    }
  }
}
