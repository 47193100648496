@import "../../_globalColor";

.education-card {
  display: flex;
  flex-direction: row;
}

.education-head {
  display: flex;
  flex-direction: row;
}

.education-text-school {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 21px;
}

.education-text-desc,
.education-text-bullets {
  font-size: 14px;
}

.education-roundedimg {
  object-fit: cover;
  margin-right: 2rem;
  width: 10.5rem;
}

.education-card-right {
  max-width: 70%;
}

.education-text-subHeader {
  color: $textColor;
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
  padding-top: 0.8rem;
  line-height: normal;
}

.education-text-duration {
  color: $titleColor;
  font-size: 16px;
  margin: 0px;
  padding-top: 0.5rem;
}

/* Media Query */

@media (max-width: 768px) {
  .education-card {
    flex-direction: column;
    padding-bottom: 10px;
  }
  .education-card-right {
    max-width: 90%;
  }
  .education-card-border {
    width: 90%;
  }
  .education-text-subHeader {
    padding-top: 0.5rem;
    font-size: 16px;
  }
  .education-text-school {
    font-size: 20px;
    height: 23px;
  }
  .education-text-duration {
    font-size: 16px;
  }

  .education-card-left {
    text-align: center;
  }
  .education-card-right {
    max-width: 100%;
  }
  .education-card-border {
    width: 100%;
  }
  .education-roundedimg {
    margin-bottom: 1rem;
    margin-right: 0;
  }
}
