@import "../../_globalColor";

.dark-mode {
  color: $textColorDark !important;
}

.footer-div {
  padding-bottom: 1rem;
  text-align: center;

  p {
    color: $subTitle !important;
  }
}

.quotation-text {
  font-size: 14px;
  font-style: italic;
  margin: 0;

  p {
    margin: 0;
  }
}

.quottation-text-link {
  font-size: 10px;
}
