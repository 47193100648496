@import "../../_globalColor";

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  // padding: 100px;
  // min-width: 50%;
  // min-height: 50%;
  border-radius: 10px;
  color: black;
  line-height: 29px;
  // height: 90vh;
  max-height: 100%;
  overflow-y: auto;
  overflow: scroll;
  overflow-x: hidden;
  max-width: 90%;
  min-width: 65vw;
}

.modal-center {
  // max-width: 40%;
}

.modal-close {
  position: absolute;
  right: 65px;
  top: 65px;
  font-size: 30px;
}

.modal-close:hover {
  font-size: 35px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-box {
    min-width: 80vw;
  }
}

// @media (min-width: 768px) {
//   .modal-box {
//     height: unset;
//   }
// }
