@import "../../../_globalColor";

.modal-container {
  .modal-title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 50px;
  }

  .modal-images {
    margin: 50px 0;
    text-align: center;

    img {
      max-width: 80%;
    }
  }

  .modal-links {
    margin: 50px 0;
    display: grid;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: $buttonColor !important;
    vertical-align: middle;
    text-decoration: none;
    border-radius: 4px;
    display: inline-flex;
    font-size: 16px;
    height: 2em;
    white-space: nowrap;
    line-height: 1.5;
    margin: 0 0.5rem 0.5rem 0;
    cursor: pointer;
    transition: 0.2s ease-in;
  }
  .software-skill-inline i {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .modal-container {
    .modal-title {
      font-size: 22px;
      line-height: 44px;
      margin: 50px 0 30px;
    }

    .modal-images {
      img {
        max-width: 100%;
      }
    }
  }
}
