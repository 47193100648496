@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-top: 60px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.software-skill-item {
  display: inline-block;
  margin-bottom: 20px;
  flex: 11%;
  min-width: 90px;
}

.software-skill-item > i {
  color: $subTitle;
}

.software-skill-item > i:hover {
  color: $skillsColor;
}
.software-skill-item > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-item > i:hover ~ p {
  color: $skillsColor;
}

@media (max-width: 1380px) {
  .software-skill-item {
    display: inline-block;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .software-skill-item {
    display: inline-block;
    margin-bottom: 20px;
    flex: 6%;
  }
}
