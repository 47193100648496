@import "../../_globalColor";

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  max-width: 277px;

  .card-title {
    text-align: left;
    color: $titleColor;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    margin: 0 0 20px;
  }
  .card-subtitle {
    text-align: left;
    color: $cardSubtitle;
    font-size: 14px;
    line-height: 22px;
  }

  .card-info-footer {
    display: flex;
    align-items: left;
    flex-wrap: wrap;
    justify-content: left;
  }

  .card-info-footer span.card-link {
    color: $buttonColor !important;
    vertical-align: middle;
    align-items: center;

    border-radius: 4px;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    white-space: nowrap;
    margin: 0 0.5rem 0.5rem 0;
    cursor: pointer;
    transition: 0.2s ease-in;
  }
  span.card-link:hover {
    background-color: $buttonColor;
    color: $lightBackground3 !important;
    padding: 0 0.75em;
  }
}

.card-info:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}
.dark-mode.card-info:hover {
  box-shadow: $achievementCertificateCardHoverDark 0px 20px 30px -10px;
}

@media (max-width: 480px) {
  .card-info {
    margin: 0 auto;
  }
}
